<main class="main legal-page">
    <div class="main-wrapper not-found-content">
        <header>
            <a [routerLink]="['/']" class="logo">
                <img src="../assets/images/account_logo.svg" >
            </a>
            <!-- <a [routerLink]="['/login']"class="account-link"><span><img src="../assets/images/account_dark.svg" ></span></a> -->
        </header>
        <div class="content ">
            <h2 class="not-found-heading">404</h2>
            <h2>К сожалению, этой страницы не существует</h2>
            <p><a [routerLink]="['/']" class="btn-black"><span>На главную</span></a></p>
        </div>
        <app-footer />
    </div>
</main>
