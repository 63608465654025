import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { AccountService } from '@app/_services/account.service';

@Component({
  selector: 'app-otp',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './otp.component.html',
  styleUrl: './otp.component.sass'
})
export class OtpComponent {
  accountService = inject(AccountService);
  router = inject(Router);

  hasActiveCode: boolean = false;
  countdown: string = '';
  email: string = '';
  expire: number = 0;
  loading: boolean = false;
  serverErrors: string[] = [];
  private countdownRef: number = 0;
  private otpLength: number = 6;
  private digits: number | null = null;
  otpForm = new FormGroup({
    otp: new FormControl('', [Validators.required])
  });

  ngOnInit() {
    this.accountService.otpCleanup();
    const expire = localStorage.getItem('otp_expire');
    if (!expire)
      return this.router.navigate(['/login']);

    this.hasActiveCode = true;
    this.startTimer(parseInt(expire));
    this.expire = parseInt(expire);
    const email = localStorage.getItem('otp_email');
    this.email = email !== null? email : '';
    return true;
  }

  ngOnDestroy() {
    clearInterval(this.countdownRef);
  }

  login() {
    const state = this.router.lastSuccessfulNavigation?.extras?.state;
    this.serverErrors = [];
    this.loading = true;
    this.accountService.login(this.email, this.digits).subscribe({
      next: (r) => {
        this.loading = false;
        this.accountService.setAuthKey(r.token);
        const redirect = (state && state['redirect'])? state['redirect'] : '/account/edit';
        this.router.navigate([redirect]);
      },
      error: (e) => {
        this.loading = false;
        if (e.error?.errors?.length) {
          const errors = e.error.errors;
          for (let error of errors) {
            this.serverErrors.push(error.detail);
          }
        }
      }
    });

    return false;
  }

  sendEmail() {
    this.loading = true;
    this.accountService.sendAuthEmail(this.email).subscribe({
        next: (r) => {
          this.loading = false;
          this.expire = r.next_attempt_at;
          localStorage.setItem('otp_expire', r.next_attempt_at.toString());
          localStorage.setItem('otp_updated_at', Math.round(new Date().getTime() / 1000).toString());
          clearInterval(this.countdownRef);
          this.hasActiveCode = true;
          this.startTimer(this.expire);
        },
        error: (e) => {
          this.loading = false;
          if (e.error?.errors?.length) {
            const errors = e.error.errors;
            for (let error of errors) {
              this.serverErrors.push(error.detail);
            }
          }
        }
    });

    return false;
  }

  inputMask(e:any) {
    const input = e.target;
    let digits =  input.value.replace(/\D/g, "");
    if (digits.length > this.otpLength) {
      digits = digits.substr(0, this.otpLength);
    }

    this.digits = digits;
    input.value = [...digits].join(' ');
    if (digits.length == this.otpLength && !this.loading) {
      this.login();
    }
  }

  private startTimer(expire:number):void {
    this.tick(expire);
    this.countdownRef = setInterval(() => {
      this.tick(expire);
    }, 1000);
  }

  private tick(expire: number):void {
      const sec = Math.round((expire * 1000 - new Date().getTime()) / 1000);
      if (sec <= 0) {
        clearInterval(this.countdownRef);
        this.hasActiveCode = false;
      }
      this.countdown = this.formatTime(sec);
  }

  private formatTime(s: number): string {
    return (s-(s%=60))/60+(9<s?':':':0')+s;
  }
}
