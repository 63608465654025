import { Component } from '@angular/core';

@Component({
  selector: 'app-subscription-plans',
  standalone: true,
  imports: [],
  templateUrl: './subscription-plans.component.html',
  styleUrl: './subscription-plans.component.sass'
})
export class SubscriptionPlansComponent {

}
