<main class="main register form-page">
    <header>
        <a [routerLink]="['/']" class="logo"><img src="../assets/images/account_logo.svg" ></a>
        <div class="back-wrapper">
            <a (click)="prevStep()" class="back-link"><span>&nbsp;</span></a>
        </div>
    </header>
    <div class="content">
        <form action="#" (keyup)="goNext($event)" [formGroup]="registerForm" [ngClass]="{'register-form': true, loading: loading }">
            <div [ngClass]="{'active': currentStep === 'name', 'field hidden-step ': true}">
                <div class="heading">
                    <h2>Как вас зовут?</h2>
                </div>
                <div class="input-box">
                    <input type="text" formControlName="name" [ngClass]="{'error': registerForm.controls['name'].invalid && registerForm.controls['name'].touched}" />
                    <p [ngClass]="{'message': true, 'error': true, 'shown': registerForm.controls['name'].invalid && registerForm.controls['name'].touched}">Это поле обязательно для заполнения</p>
                </div>
                <div class="control">
                    <button (click)="nextStep('name')" type="button" class="dark-btn">
                        <span>Далее</span>
                    </button>
                </div>
            </div>
            <div [ngClass]="{'active': currentStep === 'date_of_birth', 'field hidden-step': true}">
                <div class="heading">
                    <h2>Дата рождения</h2>
                </div>
                <div class="input-box">
                    <input type="text" (keydown)="dateKeydown($event)" maxlength="10" formControlName="date_of_birth" inputmode="decimal" placeholder="ДД.ММ.ГГГГ" [ngClass]="{'error': registerForm.controls['date_of_birth'].invalid && registerForm.controls['date_of_birth'].touched}" />
                    <p [ngClass]="{'message': true, 'error': true, 'shown': registerForm.controls['date_of_birth'].invalid && registerForm.controls['date_of_birth'].touched}">Введите дату в прошлом в формате ДД.ММ.ГГГГ</p>
                </div>
                <div class="control">
                    <button (click)="nextStep('date_of_birth')" type="button" class="dark-btn">
                        <span>Далее</span>
                    </button>
                </div>
            </div>
            <div [ngClass]="{'active': currentStep === 'cycle_type', 'field radio-list hidden-step': true}">
                <div class="heading">
                    <h2>Ваш этап <br/> жизненного цикла</h2>
                </div>
                <ng-container *ngIf="registerData !== null">
                    <div *ngFor="let type of registerData.cycle_types" class="input-box">
                        <input
                            formControlName="cycle_type"
                            type="radio"
                            [value]="type.code"
                            [id]="type.code"
                        />
                        <label [for]="type.code">
                            <p>{{ type.title }}</p>
                            <p class="light-text">{{ type.description }}</p>
                        </label>
                    </div>
                </ng-container>
                <p [ngClass]="{'message': true, 'error': true, 'shown': registerForm.controls['cycle_type'].invalid && registerForm.controls['cycle_type'].touched}">Выберите один из вариантов</p>

                <div class="control">
                    <button (click)="nextStep('cycle_type')" type="button" class="dark-btn">
                        <span>Далее</span>
                    </button>
                </div>
            </div>
            <div [ngClass]="{'active': currentStep === 'cycle_forecast_type', 'field radio-list hidden-step': true}">
                <div class="heading">
                    <h2>Прогноз циклов</h2>
                </div>
                <ng-container *ngIf="registerData !== null">
                    <div *ngFor="let type of registerData.cycle_forecast_types" class="input-box">
                        <input
                            type="radio"
                            formControlName="cycle_forecast_type"
                            [value]="type.code"
                            [id]="type.code"
                        />
                        <label [for]="type.code">
                            <p>{{ type.title}} </p>
                            <p class="light-text">{{ type.description }}</p>
                        </label>
                    </div>
                </ng-container>
                <div class="control">
                    <p [ngClass]="{'message': true, 'error': true, 'shown': registerForm.controls['cycle_forecast_type'].invalid && registerForm.controls['cycle_forecast_type'].touched}">Выберите один из вариантов</p>
                    <button (click)="nextStep('cycle_forecast_type')" type="button" class="dark-btn">
                        <span>Далее</span>
                    </button>
                </div>
            </div>
            <div [ngClass]="{'active': currentStep === 'cycles', 'hidden-step calendar-section': true, 'calendar-error':registerForm.controls['cycles'].invalid && registerForm.controls['cycles'].touched || calendarErrors.length}">
                <div class="calendar-controls">
                    <a href="#" (click)="clearDates()" class="clear-link"><span class="icon clear">&nbsp;</span><span>Очистить</span></a>
                    <a href="#" (click)="currentDate()"><span class="icon today">&nbsp;</span><span>Сегодня</span></a>
                </div>
                <div class="heading">
                    <h2>Отметьте даты прошлых месячных</h2>
                    <p>Чем больше циклов отметите, тем точнее будет прогноз.</p>
                </div>
                <div class="calendar-wrapper">
                    <dp-day-calendar #dayPicker (onSelect)="dateSelect()" formControlName="cycles" [config]="calendarConfig"></dp-day-calendar>
                    
                    <div class="control">
                        <p *ngFor="let error of calendarErrors" class="message error shown">{{ error }}</p>
                        <p [ngClass]="{'message': true, 'error': true, 'shown': registerForm.controls['cycles'].invalid && registerForm.controls['cycles'].touched}">Выберите хотя бы один период месячных</p>
                        <button (click)="nextStep('cycles', true)" type="button" class="dark-btn">
                            <span>Далее</span>
                        </button>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'active': currentStep === 'cycle_duration_manually', 'field hidden-step': true}">
                <div class="heading">
                    <h2>Средняя длина цикла</h2>
                    <p>Вспомните, сколько времени обычно проходит от первого дня одной менструации до первого дня следующей</p>
                </div>
                <div class="input-box">
                    <input type="number" formControlName="cycle_duration_manually" placeholder="Например, 28" />
                    <p [ngClass]="{'message': true, 'error': true, 'shown': registerForm.controls['cycle_duration_manually'].invalid && registerForm.controls['cycle_duration_manually'].touched}">Введите число от 20 до 40</p>
                </div>
                <div class="control">
                    <button (click)="nextStep('cycle_duration_manually')" type="button" class="dark-btn">
                        <span>Далее</span>
                    </button>
                </div>
            </div>
            <div [ngClass]="{'active': currentStep === 'cycle_duration_automatically', 'field hidden-step': true}">
                <div class="heading">
                    <h2>Средняя длина цикла</h2>
                    <p>Вспомните, сколько времени обычно проходит от первого дня одной менструации до первого дня следующей</p>
                </div>
                <div class="input-box">
                    <input type="number" formControlName="cycle_duration_automatically" placeholder="Например, 28" />
                    <p [ngClass]="{'message': true, 'error': true, 'shown': registerForm.controls['cycle_duration_automatically'].invalid && registerForm.controls['cycle_duration_automatically'].touched}">Введите число от 20 до 40</p>
                </div>
                <div class="control">
                    <button (click)="nextStep('cycle_duration_automatically')" type="button" class="dark-btn">
                        <span>Далее</span>
                    </button>
                </div>
            </div>
            <div [ngClass]="{'active': currentStep === 'period_duration_manually', 'field hidden-step': true}">
                <div class="heading">
                    <h2>Средняя продолжительность месячных</h2>
                    <p>Вспомните, сколько времени обычно идут месячные</p>
                </div>
                <div class="input-box">
                    <input type="number" formControlName="period_duration_manually" placeholder="Например, 6" />
                    <p [ngClass]="{'message': true, 'error': true, 'shown': registerForm.controls['period_duration_manually'].invalid && registerForm.controls['period_duration_manually'].touched}">Введите число от 2 до 10</p>
                </div>
                <div class="control">
                    <button (click)="nextStep('period_duration_manually')" type="button" class="dark-btn">
                        <span>Далее</span>
                    </button>
                </div>
            </div>
            <div [ngClass]="{'active': currentStep === 'diet_type', 'field radio-list hidden-step': true}">
                <div class="heading">
                    <h2>Ваш тип питания</h2>
                </div>
                <ng-container *ngIf="registerData !== null">
                    <div *ngFor="let type of registerData.diet_types" class="input-box">
                        <input
                            type="radio"
                            formControlName="diet_type"
                            [value]="type.code"
                            [id]="type.code"
                        />
                        <label [for]="type.code">
                            <p>{{ type.title }} </p>
                            <p class="light-text">{{ type.description }}</p>
                        </label>
                    </div>
                </ng-container>
                <div class="control">
                    <p [ngClass]="{'message': true, 'error': true, 'shown': registerForm.controls['diet_type'].invalid && registerForm.controls['diet_type'].touched}">Выберите один из вариантов</p>
                    <button (click)="nextStep('diet_type')" type="button" class="dark-btn">
                        <span>Далее</span>
                    </button>
                </div>
            </div>
            <div [ngClass]="{'active': currentStep === 'email', 'field hidden-step': true}">
                <div class="heading">
                    <h2>Укажите почту</h2>
                    <p>На нее придет код для подтверждения регистрации. Никаких ненужных писем, мы ценим ваше внимание.</p>
                </div>
                <div class="input-box">
                    <input type="email" formControlName="email" />
                    <p *ngFor="let error of serverErrors" class="message error shown">{{ error }}</p>
                    <p *ngIf="registerForm.controls['email'].invalid && registerForm.controls['email'].touched" [ngClass]="{'message': true, 'error': true, 'shown': registerForm.controls['email'].invalid && registerForm.controls['email'].touched}">Введите корректный Email</p>
                    <p *ngIf="registerForm.controls['agree'].status == 'INVALID' && registerForm.controls['agree'].touched" [ngClass]="{'message': true, 'error': true, 'shown': registerForm.controls['agree'].invalid && registerForm.controls['agree'].touched}">Подтвердите согласие</p>
                </div>
                <div class="control">
                    <button (click)="nextStep('email')" type="button" class="dark-btn">
                        <span>Далее</span>
                    </button>
                    <p>
                        <label for="agree" class="label-agree">
                            <input type="checkbox" id="agree" formControlName="agree" class="agree-check" />
                            <p>Я ознакомлен (-на) и согласен (-на) с <a [routerLink]="['/public-offer']" target="_blank">Публичной офертой</a> и <a [routerLink]="['/privacy-policy']" target="_blank">Политикой конфиденциальности</a>, даю свое согласие на обработку моих персональных данных</p>
                        </label> 
                    </p>
                    
                </div>
            </div>
        </form>
    </div>
    <div class="bottom-wrapper">
        <p><span>Есть аккаунт? </span><a [routerLink]="['/login']">Войти</a></p>
    </div>
</main>
