import { inject } from '@angular/core';
import { HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { Router } from '@angular/router';
import { AccountService } from '@app/_services/account.service';

//import { environment } from '@environments/environment';

export function tokenInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
    // add auth header with jwt if user is logged in and request is to the api url
    const accountService = inject(AccountService);
    const router = inject(Router);
    //const user = accountService.userValue;
    //const isLoggedIn = user?.token;
    //const isApiUrl = request.url.startsWith(environment.apiUrl);
    //if (isLoggedIn && isApiUrl) {
    const token = accountService.getAuthKey()
    if (token && router.url !== '/') {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${token}` }
      });
    }

    return next(request);
}
