<main class="main legal-page">
    <div class="main-wrapper not-found-content">
        <header>
            <a [routerLink]="['/']" class="logo">
                <img src="../assets/images/account_logo.svg" >
            </a>
        </header>
        <div class="content ">
            <div class="preloaders">
                <p class="response-preloader"> </p>
            </div>
            <h2>Благодарим за оплату!</h2>
            <p>
                Платеж обрабатывается. <br/>
                Ваш тариф будет активирован в самое ближайшее время. <br/>
                Проверить статус подписки вы всегда можете в личном кабинете.
            </p>
        </div>
        <app-footer />
    </div>
</main>
