import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '@app/_components/footer/footer.component';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { CustomerInfo } from '@app/_models';
import { AccountService } from '@app/_services/account.service';

@Component({
  selector: 'app-edit',
  standalone: true,
  imports: [CommonModule, RouterModule, FooterComponent],
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.sass'
})
export class EditComponent {
  accountService = inject(AccountService);
  customerInfo: CustomerInfo | null = null;
  planTitle: string = '';
  isPremium: boolean = false;
  isTrial: boolean = false;
  planPrice: string = 'Бесплатно';
  activeTill: string = '';
  activeSubscription: boolean = false;
  paymentInfo: string = '';
  inApp = false;
  loading: boolean = true;

  ngOnInit() {
    dayjs.extend(localizedFormat);
    dayjs.locale('ru');
    this.init();
  }

  private init() {
    this.accountService.getCustomerInfo().subscribe({
      next: (r) => {
        this.customerInfo = r;
        this.loading = false;
        if (
          this.customerInfo !== null &&
          this.customerInfo.premium_subscription !== null
        ) {
          this.isPremium = true;
          if (this.customerInfo.premium_subscription.status === 'active') {
            console.log(this.customerInfo.premium_subscription.status);
            this.activeSubscription = true;
          } else {
            this.activeSubscription = false;
          }
          this.planTitle = this.customerInfo.premium_subscription.subscription_type.description;
          this.planPrice = this.customerInfo.premium_subscription.subscription_type.is_trial?
            'Бесплатно' : `Стоимость ${this.customerInfo.premium_subscription.subscription_type.price_title}`;
          this.activeTill = this.customerInfo.premium_subscription.next_payment_date?
            `Следующее списание ${dayjs(this.customerInfo.premium_subscription.next_payment_date).format('ll')}` :
            `Действует до ${dayjs(this.customerInfo.premium_subscription.end_date).format('ll')}`
          this.isTrial = this.customerInfo.premium_subscription.subscription_type.is_trial? true : false;
          if (this.customerInfo.premium_subscription.payment_method_data) {
            const payment_data = this.customerInfo.premium_subscription.payment_method_data;
            this.paymentInfo = (payment_data.type == 'bank_card')?
              payment_data.card.card_type + ' *' + payment_data.card.last4 :
              'ЮMoney кошелёк';
          }
          this.inApp = this.customerInfo.premium_subscription.payment_method !== 'yookassa';
        }
      }
    });
  }

  cancelSubscription() {
    if (confirm('Отменить подписку? Весь функционал будет доступен до конца оплаченного периода')) {
      this.accountService.cancelSubscription().subscribe({
        next: () => {
          this.init();
        }
      });

    }
    return false;
  }

  logout() {
    this.accountService.logout();
    return false;
  }
}
