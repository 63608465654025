<main class="main login form-page">
    <header>
        <a [routerLink]="['/']" class="logo"><img src="../assets/images/account_logo.svg" ></a>
        <div class="back-wrapper">
            <a [routerLink]="['/']" class="back-link"><span>&nbsp;</span></a>
        </div>
    </header>
    <div class="content">
        <form (submit)="choosePlan()" action="#" class="login-form" [formGroup]="planForm">
            <h2>Варианты подписки</h2>
            <p class="legend">Введи почту от своего аккаунта, я отправлю<br/> на нее код подтверждения для входа.</p>
            <div class="field">
                <div class="input-box">
                    <input type="email" formControlName="plan" [ngClass]="{'error': planForm.invalid && planForm.touched}" />
                    <p *ngFor="let error of serverErrors" class="message error shown">{{ error }}</p>
                    <p *ngIf="planForm.invalid && planForm.touched" class="message error shown">Выберите план подписки</p>
                </div>
                <div class="control">
                    <button class="dark-btn">
                        <span>Продолжить</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="bottom-wrapper">
        <p><span>Нет аккаунта? </span><a [routerLink]="['/register']">Зарегистрироваться</a></p>
    </div>
</main>
