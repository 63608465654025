<div *ngIf="showCookieBanner" class="candie-banner">
    <p>
        Мы используем cookie для быстрой и удобной работы
        сайта. Продолжая навигацию по сайту, вы разрешаете их использование и даете свое согласие на
        <a [routerLink]="['/cookie-policy']"  target="_blank">обработку файлов "cookiе"</a>.
    </p>
    <button (click)="closeCookieBanner()" type="button">Ok</button>
</div>
<div class="footer-links">
    <ul class="links-wrapper">
        <li class="copyright"><p>© Ēastragene 2024</p></li>
        <li class="for-customer">
            <p><a href="mailto:ya.tsokunova@eastragene.com">Центр поддержки</a></p>
            <p><a [routerLink]="['/public-offer']" target="_blank">Публичная оферта</a></p>
            <p><a [routerLink]="['/privacy-policy']" target="_blank">Политика конфиденциальности</a></p>
        </li>
        <li class="by-aia">
            <p>
                <a href="https://aia.ws/" target="_blank">
                    Сделано с
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.257812 5.61391C0.257812 9.66641 3.60781 11.8256 6.05948 13.7589C6.92448 14.4406 7.75781 15.0831 8.59115 15.0831C9.42448 15.0831 10.2578 14.4414 11.1228 13.7581C13.5753 11.8264 16.9245 9.66641 16.9245 5.61475C16.9245 1.56225 12.3411 -1.31192 8.59115 2.58475C4.84115 -1.31275 0.257812 1.56141 0.257812 5.61391Z" fill="#E8423F"/>
                    </svg>
                    в AIA
                </a>
            </p>
        </li>
    </ul>
</div>
