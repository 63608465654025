import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.sass'
})
export class FooterComponent {
  showCookieBanner: boolean = false;

  ngOnInit() {
    if (!localStorage.getItem('cookie_shown')) {
      this.showCookieBanner = true;
    }
  }

  closeCookieBanner() {
    this.showCookieBanner = false;
    localStorage.setItem('cookie_shown', '1');
  }

}
