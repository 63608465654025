import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AccountService } from '@app/_services/account.service';
import { SubscriptionPlan } from '@app/_models';
import { FooterComponent } from '@app/_components/footer/footer.component';

@Component({
  selector: 'app-homepage',
  standalone: true,
  imports: [RouterModule, CommonModule, FooterComponent],
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.sass'
})
export class HomepageComponent {
  accountService = inject(AccountService);
  payedPlans: SubscriptionPlan[] = [];
  freePlans: SubscriptionPlan[] = [];
  currentPlan: SubscriptionPlan | undefined = undefined;

  ngOnInit() {
    this.accountService.getSubscriptionsList().subscribe({
      next: (r) => {
        this.freePlans = r.filter((plan) => plan.is_trial);
        this.payedPlans = r.filter((plan) => !plan.is_trial);
        this.currentPlan = this.payedPlans[0];
      }
    });
  }

  setPlan(index: string) {
    this.currentPlan = this.payedPlans.find((plan)=> plan.id == index);
    console.log(this.currentPlan);
    console.log(index, this.payedPlans);
    return false;
  }
}
