import { Component, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { SubscriptionPlansComponent } from '@app/_components/subscription-plans/subscription-plans.component';
import { AccountService } from '@app/_services/account.service';
import { DpDatePickerModule, DatePickerComponent } from 'ng2-date-picker';
import { WeekDays } from 'ng2-date-picker/lib/common/types/week-days.type';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { RegisterOptionsResponse, RegisterRequest, RequestCycle, RegisterFields, RegisterSteps, DateRange } from '@app/_models';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [RouterModule, ReactiveFormsModule, SubscriptionPlansComponent, CommonModule, DpDatePickerModule],
  templateUrl: './register.component.html',
  styleUrl: './register.component.sass'
})
export class RegisterComponent {
  @ViewChild('dayPicker') datePicker!: DatePickerComponent;
  accountService = inject(AccountService);
  router = inject(Router);

  serverErrors: string[] = [];
  currentStep: string = 'name';
  location: string = 'moscow';
  loading: boolean = false;
  registerData: RegisterOptionsResponse | null = null;
  calendarErrors: string[] = [];
  calendarConfig = {
    allowMultiSelect: true,
    format: 'YYYY-MM-DD',
    enableMonthSelector: false,
    firstDayOfWeek: <WeekDays>'mo',
    max: dayjs(),
  };

  private singlePeriod = true;
  private rangeValidation = {
    period_min: 2,
    period_max: 10,
    cycle_min: 20,
    cycle_max: 40
  };
  registerFields: RegisterFields = {
    location: new FormControl(this.location, [Validators.required]),
    cycle_type: new FormControl('menstrual', [Validators.required]), // exclude cycle type
    cycle_forecast_type: new FormControl('', [Validators.required]),
    cycle_duration_manually: new FormControl('', [
      Validators.required,
      Validators.min(this.rangeValidation.cycle_min),
      Validators.max(this.rangeValidation.cycle_max)
    ]),
    cycle_duration_automatically: new FormControl('', [
      Validators.required,
      Validators.min(this.rangeValidation.cycle_min),
      Validators.max(this.rangeValidation.cycle_max)
    ]),
    period_duration_manually: new FormControl('', [
      Validators.required,
      Validators.min(this.rangeValidation.period_min),
      Validators.max(this.rangeValidation.period_max)
    ]),
    cycles: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    name: new FormControl('', [Validators.required]),
    date_of_birth: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/),
      this.validateDob
    ]),
    diet_type: new FormControl('', [Validators.required]),
    agree: new FormControl('', [Validators.required])
  }
  registerForm = new FormGroup(this.registerFields);
  steps: RegisterSteps = {
    name: {
      active: true,
      prev_step: '',
      next_step: 'date_of_birth',
    },
    date_of_birth: {
      prev_step: 'name',
      active: true,
      //next_step: 'cycle_type', // exclude cycle type
      next_step: 'cycle_forecast_type',
    },
    //cycle_type: { // exclude cycle type
      //prev_step: 'date_of_birth',
      //active: true,
      //next_step: 'dynamic'
    //},
    cycle_forecast_type: {
      //prev_step: 'cycle_type', // exclude cycle type
      prev_step: 'date_of_birth',
      active: true,
      next_step: 'cycles'
    },
    cycles: {
      prev_step: 'cycle_forecast_type',
      active: true,
      next_step: 'dynamic'
    },
    cycle_duration_manually: {
      prev_step: 'cycles',
      active: true,
      next_step: 'period_duration_manually'
    },
    cycle_duration_automatically: {
      prev_step: 'cycles',
      active: true,
      next_step: 'diet_type'
    },
    period_duration_manually: {
      prev_step: 'cycle_duration_manually',
      active: true,
      next_step: 'diet_type'
    },
    diet_type: {
      prev_step: 'dynamic',
      active: true,
      next_step: 'email'
    },
    email: {
      prev_step: 'diet_type',
      active: true,
      next_step: ''
    }
  }

  ngOnInit() {
    dayjs.locale('ru');
    this.accountService.getRegisterOptions().subscribe({
      next: (r) => {
        this.registerData = r;
      }
    });
  }

  nextStep(current: string, validateRange?: boolean): boolean {
    const val = this.registerForm.value;
    if (validateRange)
      this.calendarErrors = [];
    if (this.validateField(current) && (validateRange? this.validateRanges() : true)) {
      if (!this.steps[current].next_step) {
        if (this.validateField('agree')) {
          this.submit();
        }
        return true;
      }
      if (this.steps[current].next_step == 'dynamic')
        switch (current) {
          case 'cycle_type':
            this.currentStep = val['cycle_type'] == 'menstrual'?
              'cycle_forecast_type' : 'diet_type';
            break;
          case 'cycles':
            if (val['cycle_forecast_type'] == 'automatically')
              if (this.singlePeriod)
                this.currentStep = 'cycle_duration_automatically';
              else
                this.currentStep = 'diet_type';
            else
              this.currentStep = 'cycle_duration_manually';
            break;
        }
      else
        this.currentStep = this.steps[current].next_step;
    }

    return true;
  }

  prevStep() {
    const val = this.registerForm.value;
    if (this.steps[this.currentStep].prev_step == 'dynamic')
      if (val['cycle_type'] !== 'menstrual')
        this.currentStep = 'cycle_type';
      else
        this.currentStep = val['cycle_forecast_type'] == 'manually'?
          'period_duration_manually' : 'cycle_duration_automatically';
    else
      if (this.steps[this.currentStep].prev_step)
        this.currentStep = this.steps[this.currentStep].prev_step;
      else
        this.router.navigate(['/']);
  }

  private validateField(field: string): boolean {
    this.registerForm.controls[field].markAsTouched();
    return this.registerForm.controls[field].valid;
  }

  dateSelect() {
    if (this.registerForm.value['cycles'].length) {
      this.registerForm.controls['cycles'].setValue(
        this.registerForm.value['cycles'].sort((a: any, b: any) => a.diff(b))
      )
    }
  }

  clearDates(): boolean {
    this.currentDate();
    this.registerForm.controls['cycles'].setValue([]);
    return false;
  }

  currentDate(): boolean {
    this.datePicker.api.moveCalendarTo(dayjs());
    return false;
  }

  validateRanges(): boolean {
    const ranges = this.getRanges();
    const now = dayjs();
    this.calendarErrors = [];
    if (!ranges.length){
      this.calendarErrors.push('Отметьте хотя бы один период месячных');
      return false;
    }

    if (ranges.length == 1)
      this.singlePeriod = true;
    else
      this.singlePeriod = false;

    for (let i = 0; i < ranges.length; i++) { //validate period duration
      const range = ranges[i];
      const errStart = range.period_started_at.format('DD.MM.YYYY');
      const errEnd = range.period_ended_at.format('DD.MM.YYYY');
      if (range.period_ended_at.diff(range.period_started_at, 'day') + 1 < this.rangeValidation.period_min
          && now.diff(range.period_ended_at, 'day') != 0) { //prriod min validation
        this.calendarErrors.push(`Месячные должны быть не менее ${this.rangeValidation.period_min} дней (${errStart} - ${errEnd})`);
        this.datePicker.api.moveCalendarTo(range.period_started_at);
      }
      if (range.period_ended_at.diff(range.period_started_at, 'day') + 1 > this.rangeValidation.period_max) { //period max validation
        this.calendarErrors.push(`Месячные должны быть не более ${this.rangeValidation.period_max} дней (${errStart} - ${errEnd})`);
        this.datePicker.api.moveCalendarTo(range.period_started_at);
      }
      if (i < ranges.length - 1) {
        if (ranges[i + 1].period_started_at.diff(range.period_started_at, 'day') > this.rangeValidation.cycle_max) { //cycle max validation
          this.calendarErrors.push(`Цикл должен быть не более ${this.rangeValidation.cycle_max} дней (${errStart} - ${ranges[i+1].period_started_at.format('DD.MM.YYYY')})`);
          this.datePicker.api.moveCalendarTo(range.period_started_at);
        }
        if (ranges[i + 1].period_started_at.diff(range.period_started_at, 'day') < this.rangeValidation.cycle_min) { //cycle min validation
          this.calendarErrors.push(`Цикл должен быть не менее ${this.rangeValidation.cycle_min} дней (${errStart} - ${ranges[i+1].period_started_at.format('DD.MM.YYYY')})`);
          this.datePicker.api.moveCalendarTo(range.period_started_at);
        }
      }
    }
    if (now.diff(ranges[ranges.length - 1].period_started_at, 'day') > this.rangeValidation.cycle_max) { //last cycle till now
        const lastStart = ranges[ranges.length - 1].period_started_at.format('DD.MM.YYYY');
        const lastEnd = now.format('DD.MM.YYYY');
        this.calendarErrors.push(`Цикл должен быть не более 40 дней (${lastStart} - ${lastEnd})`);
        this.datePicker.api.moveCalendarTo(now);

    }

    return !this.calendarErrors.length;
  }

  validateDob(c: FormControl):ValidationErrors | null {
    const now = dayjs();
    if (c.value) {
      return dayjs(c.value).isAfter(now)? {
        validateDob: {
          valid: false
        }
      } : null
    } else return {
      validateDob: {
        valid: false
      }
    }
  }

  dateKeydown(e: any) {
    const control = this.registerForm.controls['date_of_birth'];
    if (e.key !== 'Backspace') {
      if (control.value.length == 2 || control.value.length == 5) {
        control.setValue(control.value + '.');
      }
    }
  }

  private getRanges(): DateRange[] {
    const val = this.registerForm.value['cycles'];
    let ranges: DateRange[] = [];
    let start, end;

    for (let i = 0; i < val.length; i++) {
      if (i == 0) {
        start = val[i]; //start first range
        if (val.length > 1 && val[i + 1].diff(val[i], 'day') > 1) {
          end = val[i];
          ranges.push({period_started_at: start, period_ended_at: end});
          start = val[i + 1];
        }
      }

      if (i > 0 && i < val.length - 1) {
        if (val[i + 1].diff(val[i], 'day') > 1) {
          end = val[i];
          ranges.push({period_started_at: start, period_ended_at: end});
          start = val[i + 1];
        }
      }

      if (i == val.length - 1) { //end last range
          end = val[i];
          ranges.push({period_started_at: start, period_ended_at: end});
      }
    }

    return ranges;
  }

  private formatRanges(): RequestCycle[] {
    const ranges = this.getRanges();
    let result: RequestCycle[] = [];
    for (let range of ranges) {
      result.push({
        period_started_at: range.period_started_at.format('YYYY-MM-DD'),
        period_ended_at: range.period_ended_at.format('YYYY-MM-DD')
      });
    }
    return result;
  }

  goNext(e: any) {
    if (e.key === 'Enter') {
      this.nextStep(this.currentStep);
    }
  }

  private submit(): RegisterRequest {
    this.serverErrors = [];
    const val = this.registerForm.value;
    console.log(val);

    const result: RegisterRequest = {
      name: val['name'],
      date_of_birth: dayjs(val['date_of_birth'], 'DD.MM.YYYY').format('YYYY-MM-DD'),
      email: val['email'],
      location: this.location,
      diet_type: val['diet_type'],
      //cycle_type: val['cycle_type'], // exclude cycle type
      cycle_type: 'menstrual'
    }
    if (val['cycle_type'] == 'menstrual') {
      result.cycle_forecast_type = val['cycle_forecast_type'];
      result.cycles = this.formatRanges();
      if (val['cycle_forecast_type'] == 'automatically' && this.singlePeriod)
        result.cycle_duration_automatically = val['cycle_duration_automatically'];
      if (val['cycle_forecast_type'] == 'manually') {
        result.cycle_duration_manually = val['cycle_duration_manually'];
        result.period_duration_manually = val['period_duration_manually'];
      }

    }

    console.log(result);
    this.loading = true;
    this.accountService.register(result).subscribe({
        next: (r) => {
          this.loading = false;
          console.log(r);
          this.accountService.sendAuthEmail(val['email']).subscribe({
            next: (r) => {
              localStorage.setItem('otp_expire', r.next_attempt_at.toString());
              localStorage.setItem('otp_email', val['email']);
              localStorage.setItem('otp_updated_at', Math.round(new Date().getTime() / 1000).toString());
              return this.router.navigate(['/login/otp'], {
                state: {
                  redirect: '/account/subscribe'
                }
              });
            }
          });
        },
        error: (e) => {
          this.loading = false;
          console.log(e);
          if (e.error?.errors?.length) {
            const errors = e.error.errors;
            for (let error of errors) {
              this.serverErrors.push(error.detail);
            }
          }
        }
    });

    return result;
  }
}
