import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { FooterComponent } from '@app/_components/footer/footer.component';

@Component({
  selector: 'app-payment-response',
  standalone: true,
  imports: [RouterModule, CommonModule, FooterComponent],
  templateUrl: './payment-response.component.html',
  styleUrl: './payment-response.component.sass'
})
export class PaymentResponseComponent {
  router = inject(Router);
  private countdownRef: number = 0;

  ngOnInit() {
    clearTimeout(this.countdownRef);
    this.countdownRef = setTimeout(this.redirect.bind(this), 10000);
  }

  ngOnDestroy() {
    clearTimeout(this.countdownRef);
  }

  private redirect() {
    return this.router.navigate(['/account/edit']);
  }
}
