<main class="main login form-page">
    <header>
        <a [routerLink]="['/']" class="logo"><img src="../assets/images/account_logo.svg" ></a>
        <div class="back-wrapper">
            <a [routerLink]="['/']" class="back-link"><span>&nbsp;</span></a>
        </div>
    </header>
    <div class="content">
        <form (submit)="sendEmail()" action="#" [ngClass]="{'login-form': true, loading: loading}" [formGroup]="loginForm">
            <h2>Вход в аккаунт</h2>
            <p class="legend">Введите почту от своего аккаунта, я отправлю на нее код подтверждения.</p>
            <div class="field">
                <div class="input-box">
                    <input type="email" (keyup)="emailChange()" formControlName="email" [ngClass]="{'error': loginForm.invalid && loginForm.touched || serverErrors.length}" />
                    <p *ngFor="let error of serverErrors" class="message error shown">{{ error }}</p>
                    <p *ngIf="loginForm.invalid && loginForm.touched" class="message error shown">Введите корректный Email</p>
                </div>
                <div class="control">
                    <button class="dark-btn">
                        <span *ngIf="!hasOtp">Отправить код</span>
                        <span *ngIf="hasOtp">Ввести код</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="bottom-wrapper">
        <p><span>Нет аккаунта? </span><a [routerLink]="['/register']">Зарегистрироваться</a></p>
    </div>
</main>
