import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { AccountService } from '@app/_services/account.service';

@Component({
  selector: 'app-plans',
  standalone: true,
  imports: [RouterModule, CommonModule, ReactiveFormsModule],
  templateUrl: './plans.component.html',
  styleUrl: './plans.component.sass'
})
export class PlansComponent {
  accountService = inject(AccountService);
  serverErrors: string[] = [];
  planForm = new FormGroup({
    plan: new FormControl('', [Validators.required])
  });

  ngOnInit() {
    this.accountService.getSubscriptionsList().subscribe({
      next: (r) => {
        console.log(r);
      }
    });
  }

  choosePlan() {
    alert(123);
  }
}
