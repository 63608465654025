<main class="main login form-page">
    <header>
        <a [routerLink]="['/']" class="logo"><img src="../assets/images/account_logo.svg" ></a>
        <div class="back-wrapper">
            <a [routerLink]="['/login']" class="back-link"><span>&nbsp;</span></a>
        </div>
    </header>
    <div class="content">
        <form action="#" [ngClass]="{'login-form': true, loading: loading}">
            <h2>Введите код</h2>
            <p class="legend">
                Он был отправлен на почту<br/>
                <span>{{ email }}</span>
            </p>
            <div class="field">
                <div class="input-box">
                    <input [ngClass]="{'error': serverErrors.length}" (keyup)="inputMask($event)" autofocus type="text" max="999999" placeholder="_ _ _ _ _ _" />
                    <p *ngFor="let error of serverErrors" class="message error shown">{{ error }}</p>
                </div>
                <div class="control">
                    <button (click)="sendEmail()" *ngIf="!hasActiveCode" class="dark-btn">
                        <span>Отправить код еще раз</span>
                    </button>
                    <button *ngIf="hasActiveCode" disabled class="light-btn">
                        <span>Запросить код еще раз через <span class="timer">{{ countdown }}</span></span>
                    </button>
                </div>
            </div>
        </form>

    </div>
    <div class="bottom-wrapper">
        <p><span>Нет аккаунта? </span><a [routerLink]="['/register']">Зарегистрироваться</a></p>
    </div>
</main>
