import { inject } from '@angular/core';
import { HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AccountService } from '@app/_services/account.service';

export function errorInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
    const accountService = inject(AccountService);
    return next(request).pipe(catchError(err => {
        if ([401, 403].includes(err.status)) {
            accountService.selfLogout();
        }
        if (err.status == 429) {
          alert('Слишком много запросов, повторите попытку позже');
        }

        //const error = err.error?.message || err.statusText;
        //console.error(err);
        //return throwError(() => error);
        //return err;
        return throwError(err); // Re-throw the error
    }))
}
