import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { AccountService } from '@app/_services/account.service';
import { OtpResponse, ErrorResponse } from '@app/_models';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [RouterModule, CommonModule, ReactiveFormsModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.sass'
})
export class LoginComponent {
  accountService = inject(AccountService);
  router = inject(Router);

  serverErrors: string[] = [];
  private countdownRef: number = 0;
  loading: boolean = false;
  hasOtp: boolean = false;
  otpEmail: string | null = null;
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  ngOnInit() {
    this.accountService.otpCleanup();
    clearTimeout(this.countdownRef);
    const storageEmail = localStorage.getItem('otp_email');
    const expire = localStorage.getItem('otp_expire');
    const expire_num: number = (expire !== null)? parseInt(expire) : 0;
    const now = Math.round(new Date().getTime() / 1000);
    if (storageEmail !== null) {
      this.loginForm.controls['email'].setValue(storageEmail);
      if (expire_num - now > 0) {
        this.hasOtp = true;
        this.startTimer(expire_num);
      }
      this.otpEmail = storageEmail;
    } else {
      this.hasOtp = false;
    }

  }

  ngOnDestroy() {
    clearTimeout(this.countdownRef);
  }

  emailChange() {
    this.hasOtp = this.otpEmail !== null && this.otpEmail === this.loginForm.value['email'];
  }

  sendEmail() {
    this.serverErrors = [];
    if (this.loginForm.invalid) {
      this.loginForm.markAsTouched();
      return false;
    }

    if (this.hasOtp)
      return this.router.navigate(['/login/otp']);

    this.loading = true;
    if (typeof this.loginForm.value.email !== 'string')
        return false;
    const email: string = this.loginForm.value.email?
      this.loginForm.value.email : '';
    this.accountService.sendAuthEmail(this.loginForm.value.email).subscribe({
        next: (r) => {
          this.loading = false;

          localStorage.setItem('otp_expire', r.next_attempt_at.toString());
          localStorage.setItem('otp_email', email);
          localStorage.setItem('otp_updated_at', Math.round(new Date().getTime() / 1000).toString());
          return this.router.navigate(['/login/otp']);
        },
        error: (e) => {
          this.loading = false;
          if (e.error?.errors?.length) {
            const errors = e.error.errors;
            for (let error of errors) {
              this.serverErrors.push(error.detail);
            }
          }
        }
    });

    return true;
  }

  private startTimer(expire: number) {
    const now = Math.round(new Date().getTime() / 1000);
    clearTimeout(this.countdownRef);
    this.countdownRef = setTimeout(
      this.clearTimer.bind(this),
      (expire - now) * 1000
    );
  }

  private clearTimer() {
    this.hasOtp = false;
  }
}
