import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router  } from '@angular/router';
import { environment } from '@environments/environment';
import {
  OtpResponse,
  RegisterRequest,
  RegisterOptionsResponse,
  LoginResponse,
  SubscriptionActivateResponse,
  SubscriptionPlan,
  CustomerInfo,
  ErrorResponse
} from '@app/_models';

@Injectable({
  providedIn: 'root'
})

export class AccountService {
  router = inject(Router);

  private isLoggedIn = false;
  private authSecretKey = 'Token';
  private http = inject(HttpClient);

  constructor() { }

  isLoggedInUser(): boolean {
    return localStorage.getItem(this.authSecretKey)? true : false;
  }

  getAuthKey(): string | null {
    return localStorage.getItem(this.authSecretKey);
  }

  setAuthKey(key: string) {
    localStorage.setItem(this.authSecretKey, key);
  }

  sendAuthEmail(email: string | null | undefined) {
    return this.http.post<OtpResponse>(`${environment.apiUrl}client/request-otp/`, { email: email });
    //return this.http.get(`${environment.apiUrl}client/registration/`);
  }

  login(email: string | null | undefined, otp: number | null | undefined) {
    this.isLoggedIn = true;
    return this.http.post<LoginResponse>(`${environment.apiUrl}client/login/`, { email: email, otp: otp });
  }

  logout() {
    this.http.post(`${environment.apiUrl}client/logout/`, null).subscribe();
    this.isLoggedIn = false;
    localStorage.removeItem(this.authSecretKey);
    this.router.navigate(['/login']);
  }

  selfLogout() {
    this.isLoggedIn = false;
    localStorage.removeItem(this.authSecretKey);
    this.router.navigate(['/login']);
  }

  register(data: RegisterRequest) {
    return this.http.post(`${environment.apiUrl}client/registration/`, data);
  }

  getRegisterOptions() {
    return this.http.get<RegisterOptionsResponse>(`${environment.apiUrl}client/options/`);
  }

  getSubscriptionsList() {
    return this.http.get<SubscriptionPlan[]>(`${environment.apiUrl}subscription/list/`);
  }

  getCustomerInfo() {
    return this.http.get<CustomerInfo>(`${environment.apiUrl}client/`);
  }

  cancelSubscription() {
    return this.http.post(`${environment.apiUrl}subscription/deactivate/`, null);
  }

  activateSubscription(code: string) {
    return this.http.post<SubscriptionActivateResponse>(`${environment.apiUrl}subscription/activate/`, {
      payment_method: "yookassa",
      subscription_type_code: code
    });
  }

  otpCleanup() {
    const updatedAt = localStorage.getItem('otp_updated_at');
    const expiresAt = updatedAt !== null? parseInt(updatedAt) + 600 : 0;
    const now = Math.round(new Date().getTime() / 1000);
    if ((expiresAt - now) < 0) {
      localStorage.removeItem('otp_updated_at');
      localStorage.removeItem('otp_expire');
      localStorage.removeItem('otp_email');
    }
  }
}
