import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '@app/_components/footer/footer.component';

@Component({
  selector: 'app-public-offer',
  standalone: true,
  imports: [RouterModule, FooterComponent],
  templateUrl: './public-offer.component.html',
  styleUrl: './public-offer.component.sass'
})
export class PublicOfferComponent {

}
