import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../_services/account.service';

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const accountService = inject(AccountService);

  if (accountService.isLoggedInUser()) {
    return true;
  } else {
    return router.parseUrl('/login');
  }
};

export const unauthGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const accountService = inject(AccountService);

  if (!accountService.isLoggedInUser()) {
    return true;
  } else {
    return router.parseUrl('/account/edit');
  }
};
