<main class="main legal-page">
    <div class="main-wrapper">
        <header>
            <a [routerLink]="['/']" class="logo">
                <img src="../assets/images/account_logo.svg" >
            </a>
            <!-- <a [routerLink]="['/login']"class="account-link"><span><img src="../assets/images/account_dark.svg" ></span></a> -->
        </header>
        
        <div class="content">
            <div class="legal-heading">
                <h2>Публичный договор - оферта</h2>
                <strong>
                    о заключении лицензионного договора
                </strong>
                <p>(далее по тексту – «Публичная оферта»)</p>
                <em>Версия от «18» июля 2024 г., Российская Федерация, г. Москва</em>
                
            </div>
            <h3>1. ОБЩИЕ ПОЛОЖЕНИЯ. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h3>
            <ol>
                <li>
                    <strong>1.1</strong> 
                    Публичная оферта является официальным публичным предложением ОБЩЕСТВА С
                    ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ВЕДА», в лице Генерального директора Цокуновой Яны
                    Игоревны, действующего на основании Устава (далее по тексту - «Администрация»), с одной
                    стороны, адресованным к неограниченному кругу физических лиц, обладающих полной
                    дееспособностью (далее по тексту - «Пользователь»), с другой стороны, далее вместе именуемые -
                    «Стороны», а каждый индивидуально именуемый - «Сторона», заключить на одинаковых для всех
                    условиях и в электронном виде лицензионный договор (далее по тексту - «Договор») на
                    нижеизложенных условиях.
                </li>
                <li>
                    <strong>1.2 </strong>
                    Заключение между сторонами Договора осуществляется в момент принятия условий Публичной
                    оферты путем совершения Акцепта (п. 2 ст. 437 ГК РФ, п. 3 ст. 438 ГК РФ), в результате чего,
                    Пользователь становится лицензиатом, стороной лицензионного договора, заключенного с
                    лицензиаром (Администрацией). Акцепт равносилен заключению Договора между сторонами в
                    простой письменной форме на условиях, изложенных в Публичной оферте, без подписания его
                    письменного экземпляра, что создает для сторон взаимные права и обязанности, за неисполнение
                    которых наступает установленная законом ответственность.
                </li>
                <li>
                    <strong>1.3</strong> 
                    Совершение Акцепта подтверждает, что Пользователь имеет собственное волеизъявление на
                    заключение Договора, предварительно ознакомился с положениями Публичной оферты, полностью и
                    безоговорочно принимает все содержащиеся в ней условия в том виде, в котором они опубликованы,
                    без подписания ее письменного экземпляра, понимает правовую природу Договора, его содержание и
                    существенные условия, а также правовые последствия заключения.
                </li>
                <li>
                    <strong>1.4</strong> 
                    Если Пользователь не согласен с условиями Публичной оферты целиком, либо ее отдельными
                    частями, Администрация настоятельно призывает такого Пользователя воздержаться от совершения
                    Акцепта, оплаты Подписки, использования Веб-сайта и Приложения, так как указанные
                    правоотношения сторон целиком и полностью регулируются положениями Публичной оферты,
                    актуальная редакция которой опубликована Администрацией на Веб-сайте в разделе «Публичная
                    оферта» на принципах круглосуточного и свободного доступа к ней для любого лица.
                </li>
                <li>
                    <strong>1.5</strong> 
                    В случае если Пользователь является лицом с ограниченной дееспособность Администрация
                    исходит из того, что Акцепт в его интересах совершен законным представителем, который имеет
                    достаточный объем прав и полномочий действовать от его имени и в его интересах, а также
                    ознакомился, полностью и безоговорочно согласился с условиями Договора, изложенными в
                    Публичной оферте.
                </li>
                <li>
                    <strong>1.6</strong> 
                    Для целей толкования Договора стороны используют установленные ниже основные термины,
                    если в его тексте прямо не указано иное. В тексте Договора эти термины могут быть использованы в
                    другом падеже, в единственном или множественном числе, с маленькой или большой буквы, либо в
                    виде сокращений:
                    <ul>
                        <li>
                            <strong>1.6.1 </strong>
                            <strong>«Публичная оферта»</strong> — официальное предложение Администрации, которое содержит все
                            существенные условия Договора, адресованное к неограниченному кругу физических лиц,
                            обладающих полной дееспособностью, в целях заключения с ними Договора в электронном виде и на
                            одинаковых для всех, заранее установленных условиях.
                        </li>
                        <li>
                            <strong>1.6.2 </strong>
                            <strong>«Акцепт»</strong> — полное и безоговорочное принятие Пользователем условий Публичной оферты
                            путем совершения одного или нескольких конклюдентных действий: 1) активация специального
                            флажка («чекбокса») в контрольном пункте специальной формы на Веб-сайте: «Я ознакомлен (-на) и
                            согласен (-на) с Публичной офертой и Политикой конфиденциальности, даю свое согласие на
                            обработку моих персональных данных»; 2) Регистрация учетной записи; 3) Оплата стоимости
                            Подписки. Указанные конклюдентные действия, совершенные Пользователем по отдельности или в
                            совокупности, имеют для сторон юридическую силу его письменного согласия на заключение
                            Договора.
                        </li>
                        <li>
                            <strong>1.6.3 </strong>
                            <strong>«Веб-сайт»</strong> — совокупность программ для ЭВМ, баз данных, графических, аудиовизуальных и
                            информационных материалов, содержащихся в информационной системе, доступ к которой
                            обеспечивается посредством сети «Интернет», расположенный по постоянному URL адресу:
                            <a href="https://eastragene.com" target="_blank">eastragene.com</a>, включающий в себя все уровни указанного домена, как действующие, так и
                            вводимые в эксплуатацию в течении всего срока его действия, а также исходящие от него страницы
                            («лендинги»).
                        </li>
                        <li>
                            <strong>1.6.4 </strong>
                            <strong>«Приложение»</strong> — программа для ЭВМ под наименованием <strong>«Eastragene»</strong>, представленная в
                            объективной форме совокупностью данных и команд, включая обновления к ней и дополнительные
                            функциональные возможности, активируемых для получения определенного результата, включая
                            порождаемые ею фонограммы и аудиовизуальные изображения, предназначенные для
                            функционирования ЭВМ и других компьютерных устройств. Приложение опубликовано в AppStore и
                            Google Play, является расширенным цикл-трекером для женщин, который позволяет
                            зарегистрированному Пользователю, оплатившему стоимость Подписки, получить доступ к
                            индивидуальной программе, основанной на женских циклах и представляющей собой рекомендацию
                            по тренировкам, питанию, информацию по гормональным изменениям в каждый конкретный день,
                            календарь луны, а также представляющий доступ к блогу с полезными статьями.
                        </li>
                        <li>
                            <strong>1.6.5 </strong>
                            <strong>«Подписка»</strong> — размер вознаграждения за предоставление права использования Приложения,
                            установленный Администрацией в зависимости от ключевых параметров прав (пределов) доступа,
                            таких как объем, способы или период (срок) предоставления Пользователю права использования
                            Приложения. Разновидности доступных к оплате Подписок круглосуточно опубликованы
                            Администрацией в свободном доступе на Веб-сайте и в Приложении, являются неотъемлемой частью
                            Договора.
                        </li>
                        <li>
                            <strong>1.6.6 </strong>
                            <strong>«Рекуррентные платежи»</strong> («Автоплатеж») — сервис по осуществлению регулярных
                            автоматических переводов денежных средств за Подписку с банковской карты Пользователя в пользу
                            Администрации за предоставление права использования Приложения на основании заранее данного
                            держателем карты Акцепта.
                        </li>
                        <li>
                            <strong>1.6.7 </strong>
                            <strong>«Администрация»</strong> — ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ВЕДА», в
                            лице Генерального директора Цокуновой Яны Игоревны, действующего на основании Устава (ОГРН:
                            1237700856887; ИНН: 7743433550), являющееся на законных основаниях правообладателем Веб-сайта
                            и Приложения.
                        </li>
                        <li>
                            <strong>1.6.8 </strong>
                            <strong>«Пользователь»</strong> — совершившее Акцепт физическое лицо, обладающее полной
                            дееспособностью, либо законный представитель физического лица с частичной/ограниченной
                            дееспособностью, обладающий достаточным объемом прав и полномочий, действовать от его имени и
                            в его интересах.
                        </li>
                        <li>
                            <strong>1.6.9 </strong>
                            <strong>«Учётная запись»</strong> («Аккаунт») — хранимая в компьютерной системе уникальная совокупность
                            регистрационных данных, содержащая информацию, необходимую для аутентификации и авторизации
                            Пользователя в Приложении, в том числе о предоставленных ему и затраченных (исчерпанных) правах
                            (пределах) доступа.
                        </li>
                        <li>
                            <strong>1.6.10 </strong>
                            <strong>«Личный кабинет» </strong> — специальный раздел, позволяющий получить доступ к личной
                            информации Пользователя и функциональным возможностям Приложения, доступный после оплаты
                            Подписки и авторизации.
                        </li>
                        <li>
                            <strong>1.6.11 </strong>
                            <strong>«Логин»</strong> — уникальное имя учётной записи определяемое Пользователем в момент
                            регистрации, необходимое для аутентификации и авторизации в Приложении с целью получения
                            доступа к личному кабинету.
                        </li>
                        <li>
                            <strong>1.6.12 </strong>
                            <strong>«Пароль»</strong> — секретный набор символов, не подлежащих разглашению третьим лицам,
                            определяемых и вводимых Пользователем в момент прохождения процедуры аутентификации и
                            авторизации учетной записи. Администрация вправе установить ограничения к содержанию пароля
                            (минимальное количество символов, знаков, формат, кодировка, безопасность).
                        </li>
                        <li>
                            <strong>1.6.13 </strong>
                            <strong>«Аутентификация»</strong> — процедура проверки подлинности авторизационных данных путем
                            сравнения введенного адреса электронной почты и пароля с адресом электронной почты и паролем,
                            сохраненным в системе безопасности.
                        </li>
                        <li>
                            <strong>1.6.14 </strong>
                            <strong>«Авторизация»</strong> — предоставление прав доступа к личному кабинету после прохождения
                            Пользователем процедуры аутентификации.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>1.7</strong> 
                    Договор может содержать иные термины при толковании которых принимается во внимание
                    буквальное значение содержащихся в нем слов и выражений. Во всех иных случаях толкование
                    терминов осуществляется в соответствии с законодательством Российской Федерации, обычаями
                    делового оборота и научной доктриной.
                </li>
                <li>
                    <strong>1.8</strong> 
                    Предложение о заключении Договора не может быть отозвано Пользователем самостоятельно и
                    действует до даты его отзыва Администрацией при условии фактической доступности и
                    работоспособности Веб-сайта и Приложения, за исключением случаев, предусмотренных Договором и
                    законодательством Российской Федерации.
                </li>
                <li>
                    <strong>1.9</strong>
                    Срок действия Договора исчисляется со дня совершения Акцепта и заканчивается в день удаления
                    учетной записи Пользователя по его обращению или по инициативе Администрации в случаях,
                    предусмотренных Договором и законодательством Российской Федерации. Обязательство
                    предоставить Пользователю доступ и право использования Приложения возникает у Администрации с
                    момента совершения оплаты Подписки, и считается исполненным на следующий день после окончания
                    срока ее действия, кроме случаев когда исчерпание Пользователем предоставленных ему прав
                    (пределов) доступа к Приложению наступило ранее либо Подписка была оплачена на новый срок и/или
                    объем прав (пределов) доступа.
                </li>
            </ol>
            <h3>2. ПРЕДМЕТ ДОГОВОРА</h3>
            <ol>
                <li>
                    <strong>2.1</strong> 
                    В порядке и на условиях предусмотренных Договором, Администрация предоставляет
                        Пользователю разрешение использовать Приложение в части базовых (активированных) и
                        дополнительных (неактивированных) данных и команд, в рамках его функциональных возможностей и
                        предоставленных прав (пределов) доступа согласно оплаченной Подписки, на условиях простой
                        (неисключительной) лицензии, а Пользователь обязуется уплатить Администрации лицензионное
                        вознаграждение и использовать Приложение в установленных пределах.
                </li>
                <li>
                   <strong>2.2</strong> 
                   Право использования Приложения предоставляется Пользователю в пределах территории всех
                    стран мира, независимо от местонахождения, без права сублицензирования третьим лицам, в части
                    базовых (активированных) данных и команд - на безвозмездной основе, в части дополнительных
                    (неактивированных) данных и команд - на возмездной основе в пределах оплаченной Подписки, что
                    позволяет Пользователю использовать дополнительные, расширенные, специальные программные
                    возможности Приложения в соответствии с перечнем данных и команд, указанном на Веб-сайте и в
                    Приложении.
                </li>
                <li>
                    <strong>2.3</strong>
                    Способы использования Приложения, предоставляемые Пользователю: право на воспроизведение
                    на компьютерном устройстве или смартфоне, возможность изменения настроек в пределах, доступных
                    в личном кабинете, право на адаптацию, внесение изменений, осуществляемых в пределах
                    функциональных возможностей Приложения, в целях получения определенного результата на
                    конкретном устройстве.
                </li>
                <li>
                    <strong>2.4</strong>
                    Администрация удаленно предоставляет Пользователю платный доступ к Приложению в течение
                    24 часов с момента оплаты стоимости Подписки с помощью информационно-телекоммуникационной
                    сети общего пользования «Интернет», о чем уведомляет Пользователя с помощью предусмотренных
                    Договором средств для обмена юридически значимыми сообщениями.
                </li>
                <li>
                    <strong>2.5</strong>
                    Обязательства Администрации по предоставлению доступа и права использования Приложения
                    считаются исполненными и принятыми Пользователем без претензий с момента уведомления о
                    предоставлении доступа к Приложению на условиях Подписки, при условии отсутствия
                    мотивированных претензий, заявленных Пользователем в течение 2 (двух) календарных дней с
                    момента предоставления доступа, а также окончания последующего (каждого в отдельности) периода
                    оплаченной Подписки.
                </li>
                <li>
                    <strong>2.6</strong>
                    Администрация предоставляет впервые зарегистрировавшемуся Пользователю бесплатный период
                    использования Приложения на условиях настоящего Договора в части дополнительных
                    (неактивированных) данных и команд на срок 21 календарный день с момента начала работы
                    Приложения, по истечению которого, желающий продолжить его использование Пользователь обязан
                    приобрести Подписку, в противном случае, его доступ к Приложению прекращается.
                </li>
                <li>
                    <strong>2.7</strong>
                    Пределы использования Приложения ограничены «правом на использование», а доступ
                    предоставляется «как есть», то есть порядок использования Приложения возможен только в тех
                    пределах и способом, который устанавливает Администрация, если иное не предусмотрено условиями
                    специальной акции. Администрация вправе временно приостановить для Пользователя доступ к
                    Приложению по техническим, технологическим или иным причинам на срок, необходимый для
                    устранения препятствий в его использовании.
                </li>
            </ol>
            <h3>3. РЕГИСТРАЦИЯ УЧЕТНОЙ ЗАПИСИ</h3>
            <ol>
                <li>
                    <strong>3.1</strong> 
                    В целях реализации Пользователем возможности получения доступа и использования Приложения
                    в полном объеме, требуется добровольная регистрация учетной записи. Для этого Пользователь
                    нажимает на кнопку «Войти», «Регистрация» или иную аналогичную кнопку, и вводит в специальную
                    форму окна регистрации достоверные данные, ответственность за полноту и достоверность которых
                    возлагается на Пользователя.
                </li>
                <li>
                    <strong>3.2</strong> 
                    После совершения указанных действий, на адрес электронной почты, указанный при регистрации
                    учетной записи, направляется специальная ссылка, которую Пользователь должен активировать в
                    целях подтверждения регистрации и активации учетной записи. С момента совершения
                    вышеуказанных действий Пользователь считается прошедшим процедуру добровольной регистрации
                    учетной записи.
                </li>
                <li>
                    <strong>3.3</strong> 
                    После аутентификации и авторизации Пользователь получает доступ к личному кабинету.
                    Последующий доступ Пользователя к учетной записи осуществляется посредством введения логина и
                    пароля в контрольном пункте специальной формы всплывающего окна. В случае невозможности
                    аутентификации Пользователь вправе обратиться к Администрации с целью восстановления доступа к
                    учетной записи, окончательное решение о восстановлении доступа принимается Администрацией
                    исходя из причин невозможности произвести аутентификацию.
                </li>
                <li>
                    <strong>3.4</strong> 
                    Пользователь обязан поддерживать регистрационные данные в актуальном состоянии.
                    Администрация не несёт ответственности за точность и правильность информации, предоставляемой
                    Пользователем при регистрации учетной записи.
                </li>
                <li>
                    <strong>3.5</strong> 
                    В случае обнаружения несанкционированной активности третьих лиц относительно учетной
                    записи Пользователя, либо возникновения подозрения о компрометации его логина и пароля,
                    Пользователь обязан известить об этом Администрацию при первой возможности.
                </li>
                <li>
                    <strong>3.6</strong> 
                    Предполагается, что любые действия, совершённые Пользователем с использованием логина и
                    пароля, совершены от имени и в интересах Пользователя, пока не доказано обратное, за исключением
                    случаев, когда Пользователь уведомил Администрацию о несанкционированном доступе к его учетной
                    записи со стороны третьих лиц и такая информация подтвердилась.
                </li>
                <li>
                    <strong>3.7</strong> 
                    Пользователь несет ответственность за сохранность доступа к логину и паролю, и не вправе
                    передавать эти данные третьим лицам. Пользователь вправе хранить доступ к паролю любыми
                    удобными для него способами, которые, однако, обеспечат надлежащую защиту данной информации и
                    соблюдение достаточных мер предосторожности.
                </li>
                <li>
                    <strong>3.8</strong> 
                    Администрация вправе отказать Пользователю в регистрации учетной записи без объяснения
                    причин либо удалить (заблокировать, приостановить действие) его учетной записи в случае
                    существенного нарушения условий Договора либо в случае возникновения у Администрации
                    обоснованных сомнений относительно несанкционированного доступа к учетной записи.
                </li>
                <li>
                    <strong>3.9</strong> 
                    Удаление учетной записи Пользователя осуществляется Администрацией по обращению
                    Пользователя либо по собственной инициативе, в случае нарушения Пользователем условий Договора,
                    что влечет за собой прекращения (ограничение) доступа к Приложению.
                </li>
            </ol>
            <h3>4. ЛИЦЕНЗИОННОЕ ВОЗНАГРАЖДЕНИЕ</h3>
            <ol>
                <li>
                    <strong>4.1</strong> 
                    Пользователь обязуется уплатить Администрации лицензионное вознаграждение за право
                    использования Приложения в части дополнительных (неактивированных) данных и команд, в размере
                    100% от цены выбранной Подписки, условия которой опубликованы Администрацией на Веб-сайте в
                    момент совершения оплаты.
                </li>
                <li>
                    <strong>4.2</strong> 
                    Сумма лицензионного вознаграждения уплачивается в рублях Российской Федерации и не
                    облагается НДС на основании п. п. 26 п. 2 ст. 149 НК РФ и ст. 346.11 НК РФ, так как Администрация
                    применяет УСН. В случае уплаты лицензионного вознаграждения в иностранной валюте производится
                    конвертация оплаченной суммы в рубли по банковскому курсу на момент оплаты. Банковские
                    комиссии и курсовые разницы при этом не входят в сумму лицензионного вознаграждения и
                    оплачиваются Пользователем самостоятельно.
                </li>
                <li>
                    <strong>4.3</strong> 
                    Уплата лицензионного вознаграждения производится Пользователем с помощью электронной
                    платежной системы (интернет-эквайринга), подключенной к Веб-сайту, путем совершения
                    безналичного платежа банковской картой. Для оплаты принимаются платежные карты: VISA,
                    MasterCard, Maestro, МИР. Обработка платежа происходит на авторизационной странице банка, где
                    Пользователю необходимо ввести данные своей банковской карты в специальную платежную форму
                    (номер карты, срок действия карты, защитный код (CVC2/CVV2)). Пользователь несет персональную
                    ответственность за достоверность вводимых платежных данных.
                </li>
                <li>
                    <strong>4.4</strong> 
                    Если банковская карта подключена к услуге 3D-Secure, то Пользователь будет автоматически
                    перенаправлен на страницу банка-эмитента в целях прохождения процедуры аутентификации в
                    соответствии с правилами и методами банка-эмитента. Если указанные Пользователем платежные
                    данные являются верными и действительными, а использование банковской карты будет признано
                    технически возможным, происходит безналичное списание денежных средств с банковской карты
                    Пользователя.
                </li>
                <li>
                    <strong>4.5</strong> 
                    Администрация в одностороннем порядке вправе устанавливать иные способы уплаты
                    лицензионного вознаграждения и изменять стоимость Подписки размещая актуальную информацию об
                    этом на Веб-сайте. После уплаты Пользователем лицензионного вознаграждения новая стоимость для
                    Пользователя, ранее уже оплатившего стоимость Подписки, изменению не подлежит.
                </li>
                <li>
                    <strong>4.6</strong> 
                    Администрация вправе предусмотреть для Пользователя льготные условия использования
                    Приложения, которые могут быть представлены: специальной акцией (скидки, распродажа),
                    реферальной программой (промокодом или ссылкой), иными индивидуальными условиями на
                    усмотрение Администрации. Указанные предложения ограничены сроком их действия, о чем
                    Администрация информирует Пользователя.
                </li>
                <li>
                    <strong>4.7</strong>
                    Обязательства Пользователя по уплате лицензионного вознаграждения (цена Подписки) за
                    предоставление права использования Приложения считаются исполненными в момент зачисления
                    банком денежных средств на расчетный счет Администрации.
                </li>
                <li>
                    <strong>4.8</strong>
                    Администрация не контролирует аппаратно-программный комплекс электронной платежной
                    системы (интернет-эквайринга) и не несет ответственности за ошибки в части обработки платежа
                    (отказ в принятии оплаты, отсутствие зачисления, двойное удержание денежных средств и др.), однако
                    содействует Пользователю в рамках доступных ему возможностей в целях разрешения возникших
                    спорных ситуаций.
                </li>
                <li>
                    <strong>4.9</strong>
                    Пользователь при совершении платежа обязан использовать собственную банковскую карту в
                    соответствии с положением Центрального Банка Российской Федерации №266-П от 24.12.2004 «Об
                    эмиссии банковских карт и об операциях, совершаемых с использованием платежных карт». Если
                    оплату в интересах Пользователя совершает третье лицо, в назначении платежа должно быть указано
                    ФИО лица, в интересах которого осуществляется платеж. В случае невыполнения указанного
                    требования Администрация вправе запросить от Пользователя квитанцию, или иной платежный
                    документ, в целях подтверждения факта совершения оплаты.
                </li>
                <li>
                    <strong>4.10</strong>
                    В случае исчерпания Пользователем предоставленных ему Подпиской прав (пределов) доступа к
                    Приложению, если такие установлены ее условиями, или окончания срока действия Подписки при
                    условии отсутствия оплаты стоимости Подписки на новый срок, доступ к Приложению в части
                    дополнительных (неактивированных) данных и команд автоматически прекращается (ограничивается)
                    до момента уплаты Пользователем лицензионного вознаграждения (цены Подписки) на новый срок.
                </li>
            </ol>
            <h3>5. РЕКУРРЕНТНЫЕ ПЛАТЕЖИ</h3>
            <ol>
                <li>
                    <strong>5.1</strong> 
                    Оплата права использования Приложения в части дополнительных (неактивированных) данных и
                    команд путем оформления Подписки возможна с помощью услуги «Автоплатеж». Для подключения
                    указанной услуги необходима привязки банковской карты Пользователя, которая может быть
                    произведена путем активации специального флажка (чекбокса) в контрольном пункте формы оплаты о
                    разрешении списаний, введения в поле данных банковской карты: номер карты, срок действия карты,
                    защитный код (CVV/CVC), а также нажатия на кнопку «Заплатить» или иную аналогичную кнопку.
                </li>
                <li>
                    <strong>5.2 </strong>
                    Если указанные Пользователем данные являются верными и действительными, а использование
                    банковской карты будет признано технически возможным, она получает статус Привязанной карты.
                    Пользователь понимает и соглашается с тем, что Привязанная карта будет использована
                    Администрацией в целях осуществления регулярных ежемесячных платежей в соответствии с
                    оформленной Подпиской, для последующей безналичной оплаты ее стоимости без необходимости
                    дополнительного ввода данных банковской карты и подтверждения платежа.
                </li>
                <li>
                    <strong>5.3 </strong>
                    Указанные в п. 5.1. Договора конклюдентные действия являются аналогом собственноручной
                    подписи Пользователя и согласием на подключение услуги «Автоплатеж», которая предоставляет для
                    Пользователя возможность оплачивать Подписку путём регулярных (ежемесячных) автоматических
                    списаний (переводов) денежных средств с его банковской карты в пользу Администрации.
                </li>
                <li>
                    <strong>5.4 </strong>
                    Пользователь дает поручение составлять от его имени распоряжения о списании денежных средств
                    со счета Пользователя (к которому привязана Привязанная карта) в пользу Администрации за право
                    использования Приложения в соответствии с условиями Подписки, а также направлять распоряжения в
                    банк-эмитент Пользователя через банк-эквайер.
                </li>
                <li>
                    <strong>5.5 </strong>
                    Пользователь дает свое согласие и поручение Администрации на автоматическое, периодическое, в
                    соответствии с оформленной Подпиской, списание денежных средств с его счета (к которому
                    привязана Привязанная карта) в пользу Администрации по его распоряжению, и признает, что
                    распоряжения на списание денежных средств с его счета являются распоряжениями самого
                    Пользователя, а действия банка-эквайера, направленные на списание денежных средств в пользу
                    Администрации, выполнены с согласия и по поручению Пользователя.
                </li>
                <li>
                    <strong>5.6 </strong>
                    Непосредственное списание денежных средств с банковской карты Пользователя осуществляет
                    банк­эмитент на основании заранее данного согласия Пользователя на списание, на условиях,
                    предусмотренных оформленной Подпиской. Запрос на перевод денежных средств, с указанием суммы
                    перевода, в банк­эмитент передается процессинговым центром в рамках заключенных с
                    Администрацией соглашений.
                </li>
                <li>
                    <strong>5.7 </strong>
                    Срок действия Подписки, равный календарному месяцу, начинает свое исчисление с даты оплаты
                    Подписки и заканчивается после истечения ее срока в соответствующий день календарного месяца,
                    следующий за днем оплаты, а если срок действия Подписки равен календарному году, то в
                    соответствующий месяц и число календарного года, следующего за датой оплаты Подписки. Списание
                    денежных средств с банковской карты Пользователя в счет оплаты стоимости Подписки на новый
                    период происходит автоматически, в последний день срока ее действия, кроме случаев, если до
                    наступления указанного дня, услуга «Автоплатеж» была отключена Пользователем. При этом
                    денежные средства, переведенные с банковской карты Пользователя до отключения (отказа) от услуги
                    «Автоплатеж», возврату не подлежат, кроме случаев, установленных Договором и законодательством
                    Российской Федерации.
                </li>
                <li>
                    <strong>5.8 </strong>
                    Списание денежных средств с банковской карты Пользователя происходит в размере цены
                    выбранной Подписки, стоимость которой публикуется Администрацией на Веб-сайте и в Приложении.
                    В случае недостаточности на банковской карте Пользователя денежных средств для списания,
                    Администрация имеет право повторять запрос на списание до момента списания необходимой суммы в
                    полном объеме или считать указанное обстоятельство отказом Пользователя от Договора с даты
                    невнесенного платежа.
                </li>
                <li>
                    <strong>5.9 </strong>
                    Доступ к Приложению считается предоставленным Пользователю на указанный срок (период)
                    Подписки с момента оплаты ее стоимости при условии отражения факта оплаты в системе учёта
                    электронных платежей. Пользователь, оформляя Подписку и подключая услугу «Автоплатеж», дает
                    Администрации указание за каждый выбранный период Подписки взимать плату, установленную
                    Администрацией на день оплаты соответствующей Подписки (абонентскую плату) в размере
                    стоимости Подписки до тех пор, пока Пользователь не откажется от продления Подписки на
                    следующий период.
                </li>
                <li>
                    <strong>5.10 </strong>
                    Пользователь понимает и соглашается с тем, что любая Подписка по умолчанию оформляется на
                    неопределённый срок с момента оплаты первого периода, если иное не указано при её оплате.
                    Пользователь вправе отказаться от продления Подписки на следующий период, в этом случае доступ к
                    Приложению в части дополнительных (неактивированных) данных и команд прекращается со дня,
                    следующего за последним днем оплаченного периода.
                </li>
                <li>
                    <strong>5.11 </strong>
                    <strong>
                        Пользователь имеет право в любой момент проверить статус и период действия Подписки в
                        личном кабинете либо отменить Подписку
                    </strong>, для чего ему необходимо перейти в личный кабинет и
                    нажать на кнопку «Отменить подписку» или иную аналогичную кнопку, либо отправить электронное
                    письмо по адресу электронной почты Администрации: <a href="mailto:Ya.tsokunova@eastragene.com">Ya.tsokunova&#64;eastragene.com</a> с темой письма
                    «Отключение (отказ) от услуги «Автоплатеж» и требованием отключить услугу «Автоплатеж»,
                    заявленным в свободной форме. Указанные действия имеют для сторон юридическую силу
                    письменного отказа Пользователя от услуги «Автоплатеж».
                </li>
                <li>
                    <strong>5.12 </strong>
                    Администрация не хранит и не обрабатывает данные банковской карты Пользователя,
                    обеспечивая лишь запросы в процессинговый центр и банк-эмитент для проведения операции по
                    банковской карте.
                </li>
                <li>
                    <strong>5.13 </strong>
                    Администрация не гарантирует возможность проведения операции по банковской карте
                    Пользователя.
                </li>
            </ol>
            <h3>6. УСЛОВИЯ ВОЗВРАТА</h3>
            <ol>
                <li>
                    <strong>6.1</strong> 
                    Уплата лицензионного вознаграждения осуществляется Пользователем за предоставление
                    доступа и права использования Приложения в соответствии с купленной Подпиской, а также перечнем
                    дополнительных (неактивированных) данных и команд, указанном на Веб-сайте и в Приложении.
                    Прекращение использования Приложения само по себе не является основанием для возврата
                    Пользователю денежных средств, уплаченных в качестве лицензионного вознаграждения, причем как
                    использованной его части, так и не использованной.
                </li>
                <li>
                    <strong>6.2</strong> 
                    Пользователь вправе получить возврат денежных средств, уплаченных в пользу
                    Администрации в качестве лицензионного вознаграждения за предоставление доступа и права
                    использования Приложения, в случае ненадлежащего исполнения Администрацией своих обязательств
                    по Договору в части предоставления доступа к платным функциям Приложения и (или) до момента
                    предоставления Пользователю доступа к Приложению в части дополнительных (неактивированных)
                    данных и команд. В исключительных (индивидуальных) случаях полный или частичный возврат
                    денежных средств может быть произведен по уважительным причинам на усмотрение Администрации.
                </li>
                <li>
                    <strong>6.3</strong> 
                    Возврат уплаченных Администрацией денежных средств, в установленном Договором порядке,
                    производится на основании заявления Пользователя, оформленного в форме электронного документа,
                    отправленного на адрес электронной почты: <a href="mailto:Ya.tsokunova@eastragene.com">Ya.tsokunova&#64;eastragene.com</a>. Заявление на возврат денежных
                    средств должно содержать данные заявителя в объеме, достаточном для его идентификации,
                    конкретные требования, иную информацию по сути обращения.
                </li>
                <li>
                    <strong>6.4</strong> 
                    Администрация рассматривает заявление на возврат денежных средств в срок не более 10
                    (десяти) календарных дней с момента получения и принимает одно из следующих решений: 1)
                    удовлетворить требования и осуществить возврат денежных средств полностью или частично; 2)
                    отказать в удовлетворении требований и мотивировать причины своего отказа.
                </li>
                <li>
                    <strong>6.5</strong> 
                    В случае удовлетворения заявления полный или частичный возврат денежных средств (в
                    зависимости от принятого решения) производится не позднее 10 (десяти) календарных дней с момента
                    получения Администрацией заявления на возврат денежных средств. В срок возврата не входит
                    операционное время банка или платежной системы, которое фактически необходимо для проведения
                    операции по возврату денежных средств.
                </li>
                <li>
                    <strong>6.6</strong> 
                    В остальных случаях Пользователь не имеет права требовать возврата того, что было
                    исполнено Администрацией в соответствии с Договором. В случае надлежащего предоставления
                    Пользователю доступа и права использования Приложения в полном соответствии с оплаченной
                    Подпиской, а также перечнем дополнительных (неактивированных) данных и команд, указанном на
                    Веб-сайте и в Приложении, возврат денежных средств, уплаченных в качестве лицензионного
                    вознаграждения, не производится.
                </li>
            </ol>
            <h3>7. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h3>
            <ol>
                <li>
                    <strong>7.1</strong> 
                    В случае возникновения спора по Договору стороны подтверждают свою приверженность и
                    стремление к урегулированию такого спора во внесудебном (досудебном) порядке. Если сторонами не
                    достигнуто соглашение об урегулировании возникшего спора во внесудебном (досудебном) порядке,
                    такой спор передается на рассмотрение по местонахождению Администрации, что является
                    соглашением сторон об определении подсудности спора.</li>
                <li>
                    <strong>7.2</strong> 
                    Сторона, получившая претензию, обязана рассмотреть ее по существу и предоставить
                    мотивированный ответ на претензию в срок 10 (десять) календарных дней с момента получения
                    претензии. По результатам рассмотрения претензии, сторона, получившая претензию, принимает одно
                    из следующих решений: 1) удовлетворить требования другой стороны и предоставить фактическое
                    исполнение (устранение нарушения); 2) отказать в удовлетворении требований другой стороны с
                    обоснованием своего решения.
                </li>
            </ol>
            <h3>8. ОТВЕТСТВЕННОСТЬ СТОРОН</h3>
            <ol>
                <li>
                    <strong>8.1</strong> 
                    Стороны несут гражданско-правовую ответственность за неисполнение или ненадлежащее
                    исполнение принятых на себя обязательств по Договору, а также иные виды ответственности в
                    соответствии с применимым законодательством, в зависимости от вида и степени допущенного
                    нарушения, иного действия (бездействия).
                </li>
                <li>
                    <strong>8.2</strong> 
                    Администрация не несет ответственности за несоответствие Приложения ожиданиям
                    Пользователя, за его субъективную (отрицательную) оценку его возможностей и полученные от
                    использования результаты, если они не оправдали его ожиданий. Указанные обстоятельства ни при
                    каких условиях не могут являться основанием считать предоставление доступа и права использования
                    Приложения ненадлежащим.
                </li>
                <li>
                    <strong>8.3</strong> 
                    Пользователю запрещается:
                    <ul>
                        <li>
                            <strong>8.3.1</strong> 
                            предоставлять Веб-сайту и Приложению недостоверные данные о себе либо персональные
                            данные и иную конфиденциальную информацию третьих лиц без их согласия, оформленного в
                            установленном законом порядке;
                        </li>
                        <li>
                            <strong>8.3.2</strong> 
                            передавать права доступа к Приложению в пользование третьим лицам, в том числе путем
                            продажи, аренды, мены или бесплатного использования, без письменного согласия Администрации;
                        </li>
                        <li>
                            <strong>8.3.3</strong> 
                            выдавать себя за другое лицо либо предоставлять недостоверные (поддельные) документы;
                        </li>
                        <li>
                            <strong>8.3.4</strong> 
                            предпринимать действия программно-технического характера, которые могут
                            рассматриваться как очевидно нарушающие нормальную работу и функционирование Веб-сайта или
                            Приложения;
                        </li>
                        <li>
                            <strong>8.3.5</strong> 
                            использовать любые устройства, программы, процедуры, алгоритмы и методы,
                            автоматические устройства или эквивалентные ручные процессы для доступа, приобретения,
                            копирования или отслеживания содержания Веб-сайта или Приложения;
                        </li>
                        <li>
                            <strong>8.3.6 </strong>
                            обходить навигационную структуру Веб-сайта или Приложения, либо иным способом
                            обеспечивать себе несанкционированный доступ к их закрытым разделам, которые правомерно не
                            доступны для Пользователя;
                        </li>
                        <li>
                            <strong>8.3.7</strong> 
                            нарушать систему безопасности или аутентификации на Веб-сайте или в Приложении;
                        </li>
                        <li>
                            <strong>8.3.8</strong> 
                            выполнять обратный поиск, отслеживать информацию о другом пользователе;
                        </li>
                        <li>
                            <strong>8.3.9</strong> 
                            использовать Веб-сайт или Приложение, и его содержание, запрещенным
                            законодательством Российской Федерации способом, а также, подстрекать к таким действиям третьих
                            лиц.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>8.4</strong> 
                    Администрация не несет ответственности за несовместимость Пользовательского устройства с
                    Приложением и за невозможность его использования в связи с этим. Администрация не принимает на
                    себя обязательства по обеспечению Пользователя программно-техническими возможностями для
                    использования Приложения. В случае если устройство Пользователя не позволяет ему использовать
                    Приложение в полной мере или частично, Администрация не несет каких-либо обязательств по
                    возмещению (возврату) Пользователю стоимости Подписки.
                </li>
                <li>
                    <strong>8.5</strong> 
                    Администрация не гарантирует круглосуточную и бесперебойную доступность Приложения.
                </li>
            </ol>
            <h3>9. ФОРС-МАЖОР</h3>
            <ol>
                <li>
                    <strong>9.1</strong> 
                    Стороны освобождаются от ответственности за полное или частичное неисполнение
                    обязательств, взятых на себя по Договору в случае, если такое нарушение явилось следствием действия
                    непреодолимой силы, а именно: пожар, стихийные бедствия, забастовка, война, массовые беспорядки,
                    крупные аварии, техногенные катастрофы, ухудшение эпидемиологической ситуации (пандемия),
                    запретительные действия органов государственной власти, вступление в действие законодательных
                    актов и распоряжений государственных органов, прямо или косвенно препятствующих осуществлению
                    сторонами своих обязательств, международные санкций или других независящих от сторон
                    обстоятельств, возникших после заключения Договора.
                </li>
                <li>
                    <strong>9.2</strong> 
                    Сторона, которая не может выполнить свои обязательства по Договору в связи с действием
                    непреодолимой силы, обязана своевременно, но не позднее 3 (трех) календарных дней после
                    наступления указанных обстоятельств, известить об этом другую сторону, с последующим
                    представлением подтверждающих указанные обстоятельства документов в течение 10 (десяти)
                    календарных дней. Информация стороны должна содержать данные о характере форс-мажорных
                    обстоятельств, а также об их влиянии на исполнение стороной своих обязательств.
                </li>
                <li>
                    <strong>9.3</strong>
                    Не уведомление или несвоевременное уведомление стороны о форс-мажорных обстоятельствах
                    лишает сторону права ссылаться на любое из вышеуказанных обстоятельств, как на основание,
                    освобождающее от ответственности за неисполнение обязательств. Если действие непреодолимой силы
                    длится более 30 (тридцати) календарных дней, любая сторона может инициировать расторжение
                    Договора.
                </li>
            </ol>
            <h3>10. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</h3>
            <ol>
                <li>
                    <strong>10.1</strong> 
                    Администрация на законных основаниях является правообладателем Веб-сайта и Приложения.
                    В ходе исполнения Договора не происходит отчуждение Администрацией исключительного
                    (имущественного) права на указанные объекты авторского права, а Пользователь обязан
                    воздерживаться от действий, которые нарушают или могут нарушить исключительное
                    (имущественное) право Администрации или исключительное (неимущественное) право автора,
                    немедленно сообщать о любых ставших известными ему фактах нарушения.
                </li>
                <li>
                    <strong>10.2</strong> 
                    Пользователю запрещено без письменного разрешения Администрации распространение
                    результатов интеллектуальной деятельности, их переработка, запись, копирование целиком или
                    отдельных частей, перевод на иностранный язык, переработка, создание новых результатов
                    интеллектуальной деятельности, производных от принадлежащих Администрации. Пользователь
                    вправе использовать Веб-сайт и Приложение только тем способом, который определен и установлен
                    Договором.
                </li>
                <li>
                    <strong>10.3</strong>
                    В случае нарушения Пользователем исключительного (имущественного) права на
                    принадлежащие Администрации объекты интеллектуальной собственности, или исключительного
                    (неимущественного) права автора, Администрация и автор, наряду с использованием других
                    применимых способов защиты и мер ответственности, установленных ст. ст. 1250, 1252, 1253 ГК РФ,
                    вправе в соответствии с п. 3 ст. 1252 ГК РФ требовать от Пользователя вместо возмещения убытков, по
                    своему выбору, выплаты компенсации в размере от 10 000,00 рублей до 5 000 000,00 рублей за каждый
                    факт каждого неправомерно используемого объекта авторского права.
                </li>
            </ol>
            <h3>11. ПОРЯДОК ВНЕСЕНИЯ ИЗМЕНЕНИЙ ИЛИ РАСТОРЖЕНИЯ ДОГОВОРА</h3>
            <ol>
                <li>
                    <strong>11.1</strong> 
                    Администрация имеет право в одностороннем порядке вносить изменения в Публичную
                    оферту путем утверждения ее новой редакции при условии уведомления об этом Пользователя не
                    позднее, чем за 1 (один) календарный день до вступления в силу вносимых изменений, путем
                    публикации Публичной оферты на Веб-сайте или отправки уведомления по известным средствам
                    связи.
                </li>
                <li>
                    <strong>11.2</strong> 
                    Пользователь принимает на себя обязательство ежедневно проверять наличие уведомлений
                    относительно принятия новой редакции Публичной оферты и своевременно знакомиться с ее
                    содержанием.
                </li>
                <li>
                    <strong>11.3</strong> 
                    Новая редакция Публичной оферты распространяется на всех Пользователей, в том числе
                    совершивших Акцепт ранее даты вступления указанных изменений в силу. При этом стороны
                    понимают и соглашаются с тем, что указанные изменения применяются к ним исключительно в той
                    части правоотношений, которые возникнут после их вступления в силу.
                </li>
                <li>
                    <strong>11.4</strong> 
                    Если Пользователь продолжает совершать встречные действия после внесения изменений в
                    Публичную оферту, подразумевается, что такие встречные действия выражают его полное и
                    безоговорочное согласие с Публичной офертой в новой редакции, на основании ранее совершенного
                    им Акцепта.
                </li>
                <li>
                    <strong>11.5</strong> 
                    Договор может быть расторгнут по следующим основаниям:
                    <ul>
                        <li>
                            <strong>11.5.1</strong>
                            По соглашению сторон.
                        </li>
                        <li>
                            <strong>11.5.2</strong>
                            По инициативе Пользователя в одностороннем (внесудебном) порядке.
                        </li>
                        <li>
                            <strong>11.5.3</strong>
                            По инициативе Администрации в одностороннем (внесудебном) порядке, в случае
                            нарушения Пользователем условий Договора, а также в иных случаях, предусмотренных
                            законодательством Российской Федерации.
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>11.6</strong> 
                    По требованию одной из сторон Договор может быть изменен или расторгнут по решению
                    суда только: 1) при существенном нарушении Договора другой стороной; 2) в иных случаях,
                    предусмотренных применимым законодательством. Существенным признается нарушение, которое
                    влечет такой ущерб для другой стороны, что она в значительной степени лишается того, на что
                    рассчитывала при заключении Договора.
                </li>
            </ol>
            <h3>12. ОБМЕН ЮРИДИЧЕСКИ ЗНАЧИМЫМИ СООБЩЕНИЯМИ</h3>
            <ol>
                <li>
                    <strong>12.1</strong> 
                    Обмен документами и юридически значимыми сообщениями, связанными с возникновением,
                    изменением или прекращением обязательств, основанных на Договоре, может осуществляться
                    сторонами с помощью внутреннего чата, если он реализован на Веб-сайте или в Приложении,
                    электронной почты, мессенджера Telegram, WhatsApp, ценным почтовым отправлением с описью
                    вложения, с использованием исключительно тех реквизитов, которые указаны Администрацией в
                    Публичной оферте и переданы Пользователем при совершении Акцепта.
                </li>
                <li>
                    <strong>12.2</strong> 
                    Документы и юридически значимые сообщения, отправленные сторонами друг другу с
                    помощью электронной почты или мессенджера, признаются электронными документами,
                    подписанными простой электронной подписью, равнозначными документу на бумажном носителе
                    подписанном сторонами и имеют юридическую силу. Ключом простой электронной подписи
                    выступает пароль, а ключом проверки простой электронной подписи выступает логин к указанным
                    средствами связи.
                </li>
                <li>
                    <strong>12.3</strong> 
                    Отправка электронных документов предполагает их предварительное документирование на
                    бумажном носителе со всеми обязательным реквизитами, переведенного в электронную форму с
                    помощью средств сканирования или фотографирования. Набранный текст (тело) электронного
                    сообщения, также признается сторонами электронным документом.
                </li>
                <li>
                    <strong>12.4</strong> 
                    Днем получения адресатом документов и иных юридически значимых сообщений является
                    день их отправки (электронный документооборот), день поступления к месту выдачи (письменный
                    документооборот).
                </li>
                <li>
                    <strong>12.5</strong> 
                    Стороны обязуются сохранять конфиденциальность доступа к указанным средствам связи и
                    незамедлительно уведомлять друг друга обо всех случаях его компрометации, а также о
                    невозможности открытия или просмотра отправлений. В отсутствие такого уведомления,
                    направленные документы и юридически значимые сообщения, признаются надлежащими.
                </li>
            </ol>
            <h3>13. КОНФИДЕНЦИАЛЬНОСТЬ</h3>
            <ol>
                <li>
                    <strong>13.1</strong> 
                    При обработке персональных данных Администрация руководствуется Конституцией
                    Российской Федерации, положениями Федерального Закона «О персональных данных» №152-ФЗ от
                    27.07.2006, иными принятыми на их исполнения нормативно-правовыми и подзаконными актами
                    законодательства Российской Федерации, Регламентом (ЕС) 2016/679 Европейского Парламента и
                    Совета Европейского Союза «О защите физических лиц в отношении обработки персональных данных
                    и о свободном перемещении таких данных, а также об отмене Директивы 95/46/ЕС».
                </li>
                <li>
                    <strong>13.2</strong> 
                    Порядок обработки персональных данных определяется Политикой конфиденциальности,
                    которая опубликована на Веб-сайте в разделе «Политика конфиденциальности». Администрация
                    использует переданные ей Пользователем данные исключительно в целях, предусмотренных
                    Политикой конфиденциальности.
                </li>
                <li>
                    <strong>13.3</strong> 
                    В случае если Пользователь собирается предоставить Администрации свои персональные
                    данные, оформить и приобрести доступ к Приложению, обязательным условием для этого является
                    предварительное его ознакомление с Политикой конфиденциальности и добровольное предоставление
                    своего согласия на обработку персональных данных установленным способом.
                </li>
                
            </ol>
            <h3>14. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
            <ol>
                <li>
                    <strong>14.1</strong> 
                    Публичная оферта содержит весь объем договоренностей между сторонами в отношении
                    предмета, срока, цены и других существенных условий Договора, которые отменяют и делают
                    недействительными все другие обязательства или представления, которые могли быть приняты или
                    сделаны сторонами в устной или письменной форме до совершения Акцепта.
                </li>
                <li>
                    <strong>14.2</strong> 
                    После совершения Акцепта все предыдущие устные или письменные соглашения между
                    сторонами признаются утратившими свою силу, а стороны руководствуются исключительно
                    условиями Договора. Во всем ином, что не предусмотрено Договором, стороны руководствуются
                    законодательством Российской Федерации, а в случае отсутствия нормы права, регулирующей
                    возникшие правоотношения, сложившимися обычаями делового оборота.
                </li>
                <li>
                    <strong>14.3</strong> 
                    Стороны признают, что если какое-либо из отдельных положений Договора становится
                    недействительным в течении срока его действия, вследствие изменения законодательства или на
                    основании решения суда, остальные положения Договора являются обязательными для сторон в
                    течении срока действия Договора.
                </li>
                <li>
                    <strong>14.4</strong> 
                    В случае изменения у какой-либо из сторон реквизитов, инициирующая изменения сторона
                    обязана незамедлительно, но не позднее 5 (пяти) рабочих дней с момента изменений, уведомить об
                    этом другую сторону. При этом стороны соглашаются с тем, что уведомления и платежи, совершенные
                    по старым адресам или реквизитам, до поступления уведомления об их изменении, считаются
                    должным и надлежащим исполнением.
                </li>
                <li>
                    <strong>14.5</strong> 
                    Публичная оферта составлена на русском языке, в соответствии с законодательством
                    Российской Федерации, опубликована на Веб-сайте в свободном доступе, ее подлинный экземпляр
                    хранится по местонахождению Администрации.
                </li>
            </ol>
            
            <h3>15. РЕКВИЗИТЫ АДМИНИСТРАЦИИ</h3>
            <table class="bordered-table">
                <tr>
                    <td>Наименование:</td>
                    <td>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ВЕДА»</td>
                </tr>
                <tr>
                    <td>ОГРН:</td>
                    <td>1237700856887</td>
                </tr>
                <tr>
                    <td>ИНН:</td>
                    <td>7743433550</td>
                </tr>
                <tr>
                    <td>Юридический адрес:</td>
                    <td>125212, г. Москва, ул. Адмирала Макарова, д. 8, стр. 1, помещ. 6/1</td>
                </tr>
                <tr>
                    <td>E-mail:</td>
                    <td><a href="mailto:Ya.tsokunova@eastragene.com">Ya.tsokunova&#64;eastragene.com</a></td>
                </tr>
            </table>
            <app-footer />
        </div>
    </div>
</main>
